import { LocaleMessages } from 'vue-i18n';
import {I18N_LOCALES_CONFIG} from '@/core/support/i18n/i18nConfig';

export function loadLocaleMessages(locales: any[]): LocaleMessages {
  const messages: LocaleMessages = {};
  locales.forEach((path: any) => {
    Object.keys(path).forEach((key: any) => {
      if (!messages[key]) {
        messages[key] = path[key];
      } else {
        messages[key] = { ...messages[key], ...path[key] };
      }
    });
  });

  return messages;
}

/**
 * Find locale by given language name
 * @param lang
 */
export function findLocaleByLangName(lang: string): string | undefined {
  return Object.keys(I18N_LOCALES_CONFIG).find((locale: any) => {
    return I18N_LOCALES_CONFIG[locale].name === lang;
  });
}
